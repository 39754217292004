"use client";

import { useErrorLogger } from "datadog";
import { Typography } from "design-system-ui";
import { Image } from "@/app/_components/common/Image";

type Custom500Props = {
  error: Error;
};

export default function Custom500({ error }: Custom500Props) {
  const logError = useErrorLogger();
  logError(error);
  return (
    <ErrorPage>
      <ErrorWrapper>
        <ErrorImage />
        <ErrorText>
          에러가 발생했습니다. <br />
          서비스 이용에 불편을 드려 죄송합니다.
        </ErrorText>
      </ErrorWrapper>
    </ErrorPage>
  );
}

const ErrorPage = ({ children }) => {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
      {children}
    </div>
  );
};

const ErrorWrapper = ({ children }) => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-tw-w-[440px] tw-tw-h-[276px] tw-desktop:tw-w-[440px] tw-desktop:tw-h-[276px] tw-tablet:tw-w-[366px] tw-tablet:tw-h-[234px] tw-mobile:tw-w-[313px] tw-mobile:tw-h-[192px]">
      {children}
    </div>
  );
};

const ErrorImage = () => {
  return (
    <Image
      alt="error_bike"
      src="/static/website/error_bike.png"
      className="tw-bg-contain tw-bg-center tw-bg-no-repeat tw-w-[175.19px] tw-mt-[30px] tw-mb-[30px] desktop:tw-w-[175.19px] desktop:tw-mt-[30px] desktop:tw-mb-[30px] tablet:tw-w-[145.99px] tablet:tw-mt-[25px] tablet:tw-mb-[25px] mobile:tw-w-[116.79px] mobile:tw-mt-[20px] mobile:tw-mb-[20px]"
    />
  );
};

const ErrorText = ({ children }) => {
  return (
    <Typography variant="body4" color="gray400" className="tw-mt-30">
      {children}
    </Typography>
  );
};
