import { css } from "styled-components";

export const sizes = {
  desktop: 1440,
  tablet: 768,
  mobile: 645
};

const Media = Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (literals: TemplateStringsArray, ...args: any[]): any => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(literals, ...args)};
    }
  `;

  return acc;
}, {});

export default Media;
