import { css } from "styled-components";
import Media from "./Media";

export const fontUtil = (font: string) => css`
  font-size: ${(props) => props.theme.fonts[`${font}Desktop`].size};
  font-weight: ${(props) => props.theme.fonts[`${font}Desktop`].weight};
  font-family: ${(props) => props.theme.fonts[`${font}Desktop`].family};
  line-height: ${(props) => props.theme.fonts[`${font}Desktop`].lineHeight};

  ${Media.tablet`
    font-size: ${(props) => props.theme.fonts[`${font}Tablet`].size};
    font-weight: ${(props) => props.theme.fonts[`${font}Tablet`].weight};
    line-height: ${(props) => props.theme.fonts[`${font}Tablet`].lineHeight};
  `};

  ${Media.mobile`
    font-size: ${(props) => props.theme.fonts[`${font}Mobile`].size};
    font-weight: ${(props) => props.theme.fonts[`${font}Mobile`].weight};
    line-height: ${(props) => props.theme.fonts[`${font}Mobile`].lineHeight};
  `};
`;

export const otherCase = css``;
